import React from 'react';

const IconLocation = props => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.75 0C4.945 0 0.25 4.695 0.25 10.5C0.25 13.11 1 15.555 2.365 17.76C3.79 20.07 5.665 22.05 7.105 24.36C7.81 25.485 8.32 26.535 8.86 27.75C9.25 28.575 9.565 30 10.75 30C11.935 30 12.25 28.575 12.625 27.75C13.18 26.535 13.675 25.485 14.38 24.36C15.82 22.065 17.695 20.085 19.12 17.76C20.5 15.555 21.25 13.11 21.25 10.5C21.25 4.695 16.555 0 10.75 0ZM10.75 14.625C8.68 14.625 7 12.945 7 10.875C7 8.805 8.68 7.125 10.75 7.125C12.82 7.125 14.5 8.805 14.5 10.875C14.5 12.945 12.82 14.625 10.75 14.625Z" fill="#6E712E"/>
    </svg>
  );
};

export default IconLocation;
