// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/ui/DISABLE_SCROLLING';
export const SET_DOM_LOADED = 'app/ui/SET_DOM_LOADED';
export const SET_FIRST_LOADED = 'app/ui/SET_FIRST_LOADED';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  domLoaded: false,
  firstLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    };
    case SET_DOM_LOADED:
      return { ...state, domLoaded: true };
    case SET_FIRST_LOADED:
      return { ...state, firstLoaded: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const setDOMLoaded = () => ({
  type: SET_DOM_LOADED,
  payload: true,
});

export const setFirstLoaded = () => ({
  type: SET_FIRST_LOADED,
  payload: true,
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.ui;
  return disableScrollRequests.some(r => r.disableScrolling);
};
