import React from 'react';

const IconWeb = props => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.235 0C6.955 0 0.25 6.72 0.25 15C0.25 23.28 6.955 30 15.235 30C23.53 30 30.25 23.28 30.25 15C30.25 6.72 23.53 0 15.235 0ZM25.63 9H21.205C20.725 7.125 20.035 5.325 19.135 3.66C21.895 4.605 24.19 6.525 25.63 9ZM15.25 3.06C16.495 4.86 17.47 6.855 18.115 9H12.385C13.03 6.855 14.005 4.86 15.25 3.06ZM3.64 18C3.4 17.04 3.25 16.035 3.25 15C3.25 13.965 3.4 12.96 3.64 12H8.71C8.59 12.99 8.5 13.98 8.5 15C8.5 16.02 8.59 17.01 8.71 18H3.64ZM4.87 21H9.295C9.775 22.875 10.465 24.675 11.365 26.34C8.605 25.395 6.31 23.49 4.87 21ZM9.295 9H4.87C6.31 6.51 8.605 4.605 11.365 3.66C10.465 5.325 9.775 7.125 9.295 9ZM15.25 26.94C14.005 25.14 13.03 23.145 12.385 21H18.115C17.47 23.145 16.495 25.14 15.25 26.94ZM18.76 18H11.74C11.605 17.01 11.5 16.02 11.5 15C11.5 13.98 11.605 12.975 11.74 12H18.76C18.895 12.975 19 13.98 19 15C19 16.02 18.895 17.01 18.76 18ZM19.135 26.34C20.035 24.675 20.725 22.875 21.205 21H25.63C24.19 23.475 21.895 25.395 19.135 26.34ZM21.79 18C21.91 17.01 22 16.02 22 15C22 13.98 21.91 12.99 21.79 12H26.86C27.1 12.96 27.25 13.965 27.25 15C27.25 16.035 27.1 17.04 26.86 18H21.79Z" fill="#6E712E"/>
    </svg>
  );
};

export default IconWeb;
