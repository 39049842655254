import React from 'react';

const IconPhone = props => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.3106 20.4578L23.0687 19.9735C22.05 19.8566 21.048 20.2073 20.3299 20.9254L17.257 23.9983C12.5308 21.5935 8.65638 17.7357 6.25154 12.9928L9.34109 9.90326C10.0592 9.18515 10.4099 8.18314 10.293 7.16442L9.80869 2.95595C9.60829 1.26922 8.18877 0 6.48534 0H3.59619C1.70906 0 0.139233 1.56983 0.256135 3.45696C1.14125 17.719 12.5475 29.1086 26.7929 29.9937C28.68 30.1106 30.2498 28.5408 30.2498 26.6536V23.7645C30.2665 22.0778 28.9973 20.6582 27.3106 20.4578Z" fill="#6E712E"/>
    </svg>
  );
};

export default IconPhone;
