import { omit } from 'lodash';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { getTeamAssociatedListing } from '../../util/api';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const SET_CATEGORIES = 'app/ManageListingsPage/SET_CATEGORIES';
export const SET_SUBCATEGORIES = 'app/ManageListingsPage/SET_SUBCATEGORIES';
export const SET_CURRENT_SUBCATEGORIES = 'app/ManageListingsPage/SET_CURRENT_SUBCATEGORIES';
export const SET_SELECTED_CATEGORY = 'app/ManageListingsPage/SET_SELECTED_CATEGORY';
export const SET_CURRENT_ATTRIBUTES = 'app/ManageListingsPage/SET_CURRENT_ATTRIBUTES';

export const SET_SELECTED_CREATOR = 'app/ManageListingsPage/SET_SELECTED_CREATOR';

export const SET_FILTERED = 'app/ManageListingsPage/SET_FILTERED';
export const SET_SELECTED_IDS = 'app/ManageListingsPage/SET_SELECTED_IDS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  categories: [],
  selectedCategory: null,
  selectedCreator: null,
  subcategories: [],
  currentSubcategories: [],
  currentAttributes: ['draft', 'live', 'sold_out'],
  filtered: false,
  selectedIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case SET_CATEGORIES:
      return { ...state, categories: payload };

    case SET_SUBCATEGORIES:
      return { ...state, subcategories: payload };

    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: payload };

    case SET_SELECTED_CREATOR:
      return { ...state, selectedCreator: payload };

    case SET_CURRENT_ATTRIBUTES:
      return { ...state, currentAttributes: payload };

    case SET_CURRENT_SUBCATEGORIES:
      return { ...state, currentSubcategories: payload };

    case SET_FILTERED:
      return { ...state, filtered: payload };

    case SET_SELECTED_IDS:
      return { ...state, selectedIds: payload };

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));

  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setCategories = e => ({
  type: SET_CATEGORIES,
  payload: e,
});

export const setSubcategories = e => ({
  type: SET_SUBCATEGORIES,
  payload: e,
});

export const setCurrentCategory = e => ({
  type: SET_SELECTED_CATEGORY,
  payload: e,
});

export const setCurrentCreator = e => ({
  type: SET_SELECTED_CREATOR,
  payload: e,
});

export const setCurrentAttributes = e => ({
  type: SET_CURRENT_ATTRIBUTES,
  payload: e,
});

export const setCurrentSubcategories = e => ({
  type: SET_CURRENT_SUBCATEGORIES,
  payload: e,
});

export const setFiltered = e => ({
  type: SET_FILTERED,
  payload: e,
});

export const setSelectedIds = e => ({
  type: SET_SELECTED_IDS,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = (queryParams, config) => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  let state = getState();
  let currentUser = state?.user?.currentUser;
  if (!currentUser) {
    let currentUserResponse = await sdk.currentUser.show();
    currentUser = currentUserResponse?.data?.data;
  }

  let [team] = currentUser?.attributes?.profile?.publicData?.teams || [];

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  const listingFields = config?.listing?.listingFields || [];
  let filterCategories = [];
  const categories = listingFields.find(lf => lf.key == 'category');
  if (categories && categories.enumOptions && categories.enumOptions.length) {
    filterCategories = categories.enumOptions;
  }

  let filterSubcategories = [];
  const subcategories = listingFields.find(lf => lf.key == 'subcategory');
  if (subcategories && subcategories.enumOptions && subcategories.enumOptions.length) {
    filterSubcategories = subcategories.enumOptions;
  }

  dispatch(setCategories(filterCategories));
  dispatch(setSubcategories(filterSubcategories));

  const callbackFn = (response) => {
    dispatch(addOwnEntities(response));
    dispatch(queryListingsSuccess(response));
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      if (team) {
        getTeamAssociatedListing({ teamId: team.id, memberId: currentUser.id, params})
          .then(res => {
            let authorListings = response?.data?.data || [];
            let creatorListings = res?.data?.data || [];

            let authorIncluded = response?.data?.included || [];
            let creatorIncluded = res?.data?.included || [];

            response.data.data = [...creatorListings, ...authorListings];
            response.data.included = [...creatorIncluded, ...authorIncluded];
            callbackFn(response);
          })
          .catch(() => {
            callbackFn(response);
          });
      } else {
        callbackFn(response);
      }
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }, config);
};

export const getListings = (params, listings) => (dispatch, getState, sdk) => {
  let filtered = false;
  let items = [];

  const { pub_category, pub_subcategory, pub_creatorId } = params;

  if (pub_category !== undefined && pub_category != null) {
    filtered = true;
    listings = listings.filter(l => l.attributes?.publicData?.category == pub_category);
  }

  if (pub_category !== undefined && pub_category != null && pub_subcategory !== undefined && pub_subcategory !== null) {
    filtered = true;
    listings = listings.filter(l => l.attributes?.publicData?.subcategory == pub_subcategory);
  }

  let attrs = params['listings-attrs'] || null;

  if (attrs && !attrs.includes('draft')) {
    filtered = true;
    listings = listings.filter(l => l.attributes?.state != 'draft');
  }

  if (attrs && !attrs.includes('live')) {
    filtered = true;
    listings = listings.filter(l => {
      let stock = l.currentStock?.attributes?.quantity || 0;
      return l.attributes?.state != 'published' || !stock;
    });
  }

  if (attrs && !attrs.includes('sold_out')) {
    filtered = true;
    listings = listings.filter(l => {
      let stock = l.currentStock?.attributes?.quantity || 0;
      return !!stock;
      // let state = l.attributes?.state || 'draft';
      // return (state == 'published' && stock) || (!['draft', 'closed'].includes(state) && state != 'published');
    });
  }

  if (pub_creatorId !== undefined) {
    filtered = true;
    listings = listings.filter(l => l.attributes?.publicData?.creatorId == pub_creatorId);
  }

  if (filtered) {
    items = listings.map(l => l.id.uuid);
  }
  dispatch(setFiltered(filtered));
  dispatch(setSelectedIds(items));
};

export const changePropsCategory = (values, subcategories) => (dispatch, getState, sdk) => {
  let currentSubcategories = [];
  let category = values.pub_category;
  if (category) {
    currentSubcategories = subcategories.filter(subcat => {
      return subcat.option.includes(`only${category}`);
    });
  }
  dispatch(setCurrentAttributes(values['listings-attrs']));
  dispatch(setCurrentCategory(category));
  dispatch(setCurrentSubcategories(currentSubcategories));
};

export const changeTeamPropsCategory = (values) => (dispatch, getState, sdk) => {
  let creator = values.pub_creatorId;
  dispatch(setCurrentCreator(creator));
};
